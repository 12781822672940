<template>
  <div :id="`${elemId}`" class="col"></div>
</template>

<script>
export default {
  name: "HSForm",
  props: {
    hsFormId: String,
    elemId: String,
  },
  created() {
    // Add a script tag for the HubSpot library.
    // Check for if it's already added first though, so no duplicates.
    if ( ! document.getElementById( `${this.elemId}-script` ) ) {

      const scriptTag = document.createElement( "script" )
      scriptTag.type  = "text/javascript"
      scriptTag.src   = "https://js.hsforms.net/forms/v2.js"
      scriptTag.id    = `${this.elemId}-script`

      // Append to the end of body tag.
      document.body.appendChild( scriptTag )
    }
  },
  mounted() {
    // If HubSpot library is already loaded, create the form.
    // Otherwise, listen for when it loads before creating the form.
    if ( window.hbspt ) {
      this.createForm()
    } else {
      const hubspotLib = document.getElementById( `${this.elemId}-script` )

      hubspotLib.onload = () => {
        if ( window.hbspt ) {
          this.createForm()
        }
      }
    }
  },
  methods: {
    createForm: function() {
      // eslint-disable-next-line no-undef
      hbspt.forms.create( {
        region: process.env.VUE_APP_HS_REGION,
        portalId: process.env.VUE_APP_HS_PORTAL,
        formId: this.hsFormId,
        target: `#${this.elemId}`,
      } )
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style scoped lang="scss">
</style>
