<template>
  <div id="tf-form" ref="tfFormContainer" v-once></div>
</template>

<script>
import { ref } from "vue"
import { createWidget } from "@typeform/embed"
import "@typeform/embed/build/css/widget.css"

export default {
  name: "TFForm",
  setup() {
    return {
      tfFormContainer: ref( null ),
    }
  },
  mounted() {
    createWidget( process.env.VUE_APP_TF_FORM, {
      container: this.tfFormContainer,
      hideHeaders: true,
      hideFooter: true,
      opacity: 0,
      inlineOnMobile: true,
      disableAutoFocus: true,
      height: 600,
        iframeProps: { title: 'TypeForm Expression of Interest form' },
    } )
  }
}
</script>

