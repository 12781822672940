<template>
  <h1>TypeForm Test</h1>
  <TFForm />
  <hr />
  <h1>HubSpot Test</h1>
  <HSForm
    :hsFormId="hsFormId"
    elemId="hsTestForm"
  />
</template>

<script>
import TFForm from './components/TFForm.vue'
import HSForm from './components/HSForm.vue'

export default {
  name: 'App',
  components: {
    TFForm,
    HSForm,
  },
  data() {
    return {
      hsFormId: process.env.VUE_APP_HS_TEST_FORM
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  background-color: black;
  margin-top: 60px;
  padding: 1rem;
}
</style>
